/*
VALOTA CONFIDENTIAL
__________________

[2013] - [2016] Valota Limited 
All Rights Reserved.

NOTICE: All information contained herein is, and remains the
property of Valota Limited and its suppliers, if any. The
intellectual and technical concepts contained herein are
proprietary to Valota Limited and its suppliers and may be covered
by Finnish and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this
information or reproduction of this material is strictly forbidden
unless prior written permission is obtained from Valota Limited.
*/
/* 
    Created on : 24-May-2016, 21:09:03
    Author     : jukka.korpelainen@valota.fi
*/
/* FILTERVARDEFINE '/img/'; */
/* FILTERVARDEFINE '/fonts/'; */
body {
  font-size: 14px; }

#account_div {
  float: right;
  display: inline-block; }

#login_menu {
  display: inline-block;
  float: right; }

@media screen and (max-width: 919px) {
  #container {
    width: auto;
    margin-left: 7px;
    margin-right: 7px; }
  .cs_img_container, .cs_text_container {
    display: block;
    margin: auto;
    padding-left: 0; }
  #cg_header {
    margin-bottom: 7px; }
  #main_menu {
    position: fixed;
    right: -50%;
    top: 0 !important;
    height: 100%;
    width: 50%;
    transition: right .5s;
    background-color: #fafafa;
    box-shadow: 0 1px 2px #ccc;
    z-index: 100; }
    #main_menu.show {
      right: 0%; }
    #main_menu li {
      display: block; }
      #main_menu li:hover, #main_menu li.chosen {
        border-bottom: 2px solid transparent; }
  #mobile_menu {
    display: block; }
  .cs_app {
    display: block;
    width: auto; } }

@media screen and (min-width: 920px) and (max-width: 1419px) {
  #page_container .padd_cont {
    width: auto;
    margin-left: 7px;
    margin-right: 7px; } }

/*
@media screen and (min-width: 920px) and (max-width: 1119px) {
#container {
    width:700px;
	margin-left:calc((100% - 900px) / 2);
	
  }
  
}

@media screen and (min-width: 1120px) and (max-width: 1219px) {
#container {
    width:700px;
  }
  
}

@media screen and (min-width: 1220px) and (max-width: 1319px) {
#container {
    width:800px;
  }
  
}

@media screen and (min-width: 1320px) and (max-width: 1419px) {
#container {
    width:900px;
  }
  
}

@media screen and (min-width: 1420px) and (max-width: 1639px) {
#container {
    width:1000px;
  }
  
}
*/
@media screen and (max-width: 1419px) {
  .box {
    margin-top: 0; }
    .box .half {
      width: 100%; }
    .box .half_pads, .box .half_nopads {
      padding: 5%;
      margin-bottom: 7px;
      background-color: #fafafa;
      box-shadow: 0 1px 2px #ccc; } }

@media screen and (min-width: 1420px) {
  .box .half {
    width: 50%;
    display: inline-block;
    display: flex;
    border-right: 7px solid transparent;
    border-bottom: 7px solid transparent;
    box-sizing: border-box;
    vertical-align: top; }
    .box .half .heading_div:before {
      width: 100%;
      left: 0; }
    .box .half h3 {
      padding: .6em 3.4em;
      font-size: 1.6em; }
  .box .half.no_border {
    border-right: 0;
    margin-right: 0; }
  .box .half_flex {
    flex: 1;
    background-color: #fafafa;
    box-shadow: 0 1px 2px #ccc; }
  .box .half_pads {
    padding: 10%; }
  .box .half.no_border .half_pads {
    border-right: 0;
    margin-right: 0; } }

@media screen and (min-width: 1420px) {
  #page_container .padd_cont {
    width: 1200px;
    margin-left: auto;
    margin-right: auto; } }
